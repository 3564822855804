<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="hardwareUpdate">
      <b-form>
        <b-row>
          <b-col md="12">
            <div class="section-block mb-4">
              <div class="section-block__title">
                <feather-icon
                  icon="ListIcon"
                  width="24"
                  height="24"
                />
                <h2>Hardware specifications</h2>
              </div>
              <b-form-group
                label="Name"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  vid="name"
                  rules="required|max:255"
                >
                  <b-form-input
                    v-model="form.name"
                    autocomplete="off"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Model"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Model"
                  vid="model_id"
                  rules="required"
                >
                  <v-select
                    v-model="form.model_id"
                    :options="modelList"
                    :reduce="model => model.id"
                    label="name"
                    class="selector-height"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-row
                class="neighbouring-form-container"
              >
                <b-col
                  class="neighbouring-form-group"
                >
                  <b-form-group
                    label="Serial number"
                    label-class="label-class"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Serial number"
                      vid="serial_number"
                      rules="required|alpha-num|min:11|max:14"
                    >
                      <b-form-row>
                        <b-col>
                          <b-form-input
                            v-model="form.serial_number"
                            :state="errors.length > 0 ? false:null"
                            autocomplete="off"
                            placeholder="Serial number"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-col>
                      </b-form-row>

                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-form-group
                  label="Warranty expiry at"
                  label-class="label-class"
                  class="neighbouring-form-group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Warranty expiry at"
                    vid="warranty_expiry_at"
                  >
                    <b-form-datepicker
                      id="warranty_expiry_at"
                      v-model="form.warranty_expiry_at"
                      :min="minDate"
                      locale="en"
                      class="mb-1"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-form-row>

              <b-form-group
                label="Site"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Site"
                  vid="site_id"
                >
                  <v-select
                    v-model="site"
                    :options="siteList"
                    placeholder="Enter name"
                    label="name"
                    class="selector-height"
                    @search="onSearch($event, 'site')"
                    @input="onSelectedUser($event, 'site')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                label="Comment"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Comment"
                  vid="comment"
                  rules="max:255"
                >
                  <b-form-input
                    id="Comment"
                    v-model="form.comment"
                    autocomplete="off"
                    placeholder="Comment"
                    class="mb-1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="section-block  mb-4">
              <div class="section-block__title">
                <feather-icon
                  icon="UsersIcon"
                  width="24"
                  height="24"
                />
                <h2>Owner details</h2>
              </div>
              <div
                class="mb-1"
              >
                <multiselect
                  v-model="selected"
                  :options="options"
                  multiple
                  placeholder="Select owner’s type"
                  class="multiselect"
                  :searchable="false"
                  @select="onSelect"
                />
              </div>
              <b-form-group
                v-if="$can('view', SELECT_CLIENT) && (selected.includes('Client') || (client || reseller))"
                label="Client"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Client"
                  vid="client_id"
                >
                  <v-select
                    v-model="client"
                    :options="clients"
                    placeholder="Enter name"
                    label="name"
                    :class="selected[0] !== 'Client' ? 'disabled-clear owner-select' : 'owner-select'"
                    @search="onSearch($event, 'client')"
                    @input="onSelectedUser($event, 'client')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                v-if="$can('view', SELECT_RESELLER) && (selected.includes('Reseller') || (reseller || distributor))"
                label="Reseller"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Reseller"
                  vid="reseller_id"
                >
                  <v-select
                    v-model="reseller"
                    :options="resellers"
                    placeholder="Enter name"
                    label="name"
                    :class="selected[0] !== 'Reseller' ? 'disabled-clear owner-select' : 'owner-select'"
                    @search="onSearch($event, 'reseller')"
                    @input="onSelectedUser($event, 'reseller')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                v-if="$can('view', SELECT_DISTRIBUTOR) && (selected.includes('Distributor') || (distributor || masterDistributor))"
                label="Distributor"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Distributor"
                  vid="distributor_id"
                >
                  <v-select
                    v-model="distributor"
                    :options="distributors"
                    placeholder="Enter name"
                    :class="selected[0] !== 'Distributor' ? 'disabled-clear owner-select' : 'owner-select'"
                    label="name"
                    @search="onSearch($event, 'distributor')"
                    @input="onSelectedUser($event, 'distributor')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                v-if="$can('view', SELECT_MASTER_DISTRIBUTOR) && (selected.includes('Master Distributor') || masterDistributor)"
                label="Master Distributor"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Master Distributor"
                  vid="master_distributor_id"
                >
                  <v-select
                    v-model="masterDistributor"
                    :options="masterDistributors"
                    placeholder="Enter name"
                    label="name"
                    :class="selected[0] !== 'Master Distributor' ? 'disabled-clear owner-select' : 'owner-select'"
                    @search="onSearch($event, 'master_distributor')"
                    @input="onSelectedUser($event, 'master_distributor')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

            </div>
            <div
              v-if="item"
              class="section-block  mb-4"
            >
              <div class="section-block__title">
                <feather-icon
                  icon="InfoIcon"
                  width="24"
                  height="24"
                />
                <h2>Additional details</h2>
              </div>
              <p class="status-label">
                Status:
              </p>
              <div class="d-flex justify-content-between align-items-center mb-1 license-status-container">
                <b-badge
                  v-if="!item.readOnly.is_online"
                  pill
                  variant="danger"
                  class="license-badge"
                >Offline</b-badge>
                <b-badge
                  v-else
                  pill
                  variant="success"
                  class="license-badge"
                >Online</b-badge>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  class="d-flex align-items-center refresh-button"
                  variant="primary"
                  :disabled="isHardwareReloading"
                  @click.prevent="handleReloadItem(item.id)"
                >
                  <feather-icon
                    icon="RefreshCwIcon"
                    width="16"
                    height="16"
                  />
                  Reload
                  <b-spinner
                    v-if="isHardwareReloading"
                    class="ml-1"
                    style="width:14px; height:14px"
                    label="Spinning"
                  />
                </b-button>
              </div>
              <b-form-group
                label="Current Script Version"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Current Script Version"
                  vid="current_system_version"
                >
                  <b-form-input
                    v-model="item.readOnly.current_system_version"
                    disabled
                    placeholder="Current Script Version"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Original RouterBOARD Version"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Original RouterBOARD Version"
                  vid="original_router_board_version"
                >
                  <b-form-input
                    v-model="item.readOnly.original_router_board_version"
                    disabled
                    placeholder="Original RouterBOARD Version"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Last Polled datetime"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Last Polled datetime"
                  vid="polled_at"
                >
                  <b-form-input
                    v-model="item.readOnly.polled_at"
                    disabled
                    placeholder="Last Polled datetime"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </b-col>
          <!-- submit button -->
          <b-col
            cols="12"
            class="text-right"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="w-100"
              type="submit"
              variant="primary"
              @click.prevent="validationForm"
            >
              <div
                v-if="isFormSubmitting"
              >
                <b-spinner
                  label="Spinning"
                  small
                />
              </div>
              {{ isFormSubmitting ? '' : 'Submit' }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BFormDatepicker, BCard, BFormRow, BBadge, BSpinner,
} from 'bootstrap-vue'
import {
  required, max, alphaNum, numeric, min,
} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import rolesPermission from '@/libs/acl_roles/rolesPermission'
import { mapActions, mapMutations, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import { debounce } from '@/utils/utils'
import Multiselect from 'vue-multiselect'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BFormRow,
    BRow,
    BCol,
    BButton,
    BFormDatepicker,
    BBadge,
    BSpinner,
    Multiselect,
    vSelect,
  },
  data() {
    return {
      form: {
        id: null,
        name: '',
        model_id: '',
        serial_number: '',
        warranty_expiry_at: null,
      },
      isFormSubmitting: false,
      isHardwareReloading: false,
      minDate: '',
      options: ['Client', 'Reseller', 'Distributor', 'Master Distributor'],
      modelList: [],
      required,
      min,
      max,
      alphaNum,
      numeric,
      selected: [],
      SELECT_MASTER_DISTRIBUTOR: rolesPermission.SELECT_MASTER_DISTRIBUTOR,
      SELECT_DISTRIBUTOR: rolesPermission.SELECT_DISTRIBUTOR,
      SELECT_RESELLER: rolesPermission.SELECT_RESELLER,
      SELECT_CLIENT: rolesPermission.SELECT_CLIENT,
      SELECT_SITE: rolesPermission.SELECT_SITE,
      HARDWARE_SELECT_ANY_WARRANTY_EXPIRY_AT: rolesPermission.HARDWARE_SELECT_ANY_WARRANTY_EXPIRY_AT,

      originSite: null,
    }
  },
  computed: {
    ...mapFields('user', [
      'masterDistributor',
      'distributor',
      'reseller',
      'client',
      'site',
      'masterDistributorList',
      'distributorList',
      'resellerList',
      'clientList',
      'siteList',
      'clients',
      'resellers',
      'distributors',
      'masterDistributors',
    ]),
    ...mapState('hardware', [
      'item',
    ]),
  },
  watch: {
    distributor(val) {
      if (!val) {
        this.selected = ['Master Distributor']
        this.client = null
        this.reseller = null
      } else if (!this.reseller && !this.client) {
        this.selected = ['Distributor']
      }
    },
    reseller(val) {
      if (!val) {
        this.selected = ['Distributor']
        this.client = null
      } else if (!this.client) {
        this.selected = ['Reseller']
      }
    },
    masterDistributor(val) {
      if (!val) {
        this.selected = ['Master Distributor']
        this.distributor = null
        this.reseller = null
        this.client = null
      } else if (!this.reseller && !this.distributor && !this.client) {
        this.selected = ['Master Distributor']
      }
    },
    client(val) {
      if (!val) {
        this.selected = ['Reseller']
      } else {
        this.selected = ['Client']
      }
    },
    site: {
      handler(val) {
        if (val && !this.originSite) {
          this.originSite = val
          const pageTitle = document.querySelector('.content-header-title__extra-info')
          const link = document.createElement('a')
          link.href = `/site/${val.id}/hardware/index`
          link.innerHTML = `(${val.name})`

          pageTitle.innerHTML = ''
          pageTitle.appendChild(link)
        }
      },
      immediate: true,
    },
  },
  async created() {
    await this.fetchModelList()

    await this.search({ query: '', company_type: 'site' }, this.fetchSiteList)
    await this.fetchCompaniesList({ query: '', company_type: 'master-distributor' })
    await this.fetchCompaniesList({ query: '', company_type: 'distributor' })
    await this.fetchCompaniesList({ query: '', company_type: 'reseller' })
    await this.fetchCompaniesList({ query: '', company_type: 'client' })

    const payload = {}
    if (this.client) {
      payload.company_id = this.client.id
      this.selected = ['Client']
    } else if (this.reseller) {
      payload.company_id = this.reseller.id
      this.selected = ['Reseller']
    } else if (this.distributor) {
      payload.company_id = this.distributor.id
      this.selected = ['Distributor']
    } else if (this.masterDistributor) {
      payload.company_id = this.masterDistributor.id
      this.selected = ['Master Distributor']
    }

    if (this.$can('view', this.SELECT_MASTER_DISTRIBUTOR)) {
      this.options = ['Client', 'Reseller', 'Distributor', 'Master Distributor']
    } else if (this.$can('view', this.SELECT_DISTRIBUTOR)) {
      this.options = ['Client', 'Reseller', 'Distributor']
    } else if (this.$can('view', this.SELECT_RESELLER)) {
      this.options = ['Client', 'Reseller']
    } else if (this.$can('view', this.SELECT_CLIENT)) {
      this.options = ['Client']
    } else {
      this.options = []
    }

    this.fetchHardwareSelectedCompany(payload)
  },
  destroyed() {
    document.querySelector('.content-header-title__extra-info').innerHTML = ''
  },
  mounted() {
    const { id } = this.$route.params

    if (id) {
      this.fetchModel(id)
    } else {
      this.$router.push({ name: 'error-404' })
    }

    if (!this.$can('view', this.HARDWARE_SELECT_ANY_WARRANTY_EXPIRY_AT)) {
      this.minDate = new Date()
    }
  },
  methods: {
    ...mapActions('user', [
      'fetchCompaniesList',
      'fetchHardwareSelectedCompany',
    ]),
    ...mapActions('hardware', [
      'getItem',
      'updateItem',
      'reloadItem',
    ]),
    ...mapMutations({
      selectCompanies: 'user/SELECT_COMPANIES',
      resetUserState: 'user/RESET_STATE',
    }),
    onSelect(option) {
      if (option === 'Client') {
        this.selected = ['Client']
      }

      if (option === 'Reseller') {
        this.selected = ['Reseller']
        this.client = null
      }

      if (option === 'Distributor') {
        this.selected = ['Distributor']
        this.reseller = null
        this.client = null
      }

      if (option === 'Master Distributor') {
        this.selected = ['Master Distributor']
        this.distributor = null
        this.reseller = null
        this.client = null
      }
    },
    /**
     * @params {number} id
     */
    async handleReloadItem(id) {
      this.isHardwareReloading = true
      try {
        const { data } = await this.reloadItem(id)
        this.item.readOnly = data
        // eslint-disable-next-line prefer-destructuring
        this.item.readOnly.polled_at = new Date(this.item.readOnly.polled_at).toISOString().replace('T', ' ').replace('Z', '')
          .split('.')[0]
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        const { status } = error.response
        if (!status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.message,
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      } finally {
        this.isHardwareReloading = false
      }
    },
    async fetchModel(id) {
      try {
        const { data } = await this.getItem(id)
        this.form = {
          ...this.item,
        }
        this.selectCompanies(data)
      } catch (error) {
        const { status } = error.response
        if (status === 404) {
          this.$router.push({ name: 'error-404' })
        }
        if (!status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
    onSearchFetch(query, type) {
      let payload = {
        query,
        company_type: type,
      }

      if (type === 'distributor') {
        this.search(payload, this.fetchCompaniesList)
      }

      if (type === 'reseller') {
        if (this.distributor) {
          payload = {
            ...payload,
            ...{ distributor_id: this.distributor.id },
          }
        }

        this.search(payload, this.fetchCompaniesList)
      }

      if (type === 'client') {
        if (this.distributor) {
          payload = {
            ...payload,
            ...{ distributor_id: this.distributor.id },
          }
        }

        if (this.reseller) {
          payload = {
            ...payload,
            ...{ reseller_id: this.reseller.id },
          }
        }

        this.search(payload, this.fetchCompaniesList)
      }

      if (type === 'site') {
        if (this.distributor) {
          payload = {
            ...payload,
            ...{ distributor_id: this.distributor.id },
          }
        }

        if (this.reseller) {
          payload = {
            ...payload,
            ...{ reseller_id: this.reseller.id },
          }
        }

        if (this.client) {
          payload = {
            ...payload,
            ...{ client_id: this.client.id },
          }
        }

        this.search(payload, this.fetchSiteList)
      }
    },
    onSearch(query, type) {
      if (query.length) {
        this.onSearchFetch(query, type)
      }
    },
    search: debounce((payload, fetchAction) => {
      fetchAction({ ...payload })
    }, 350),
    async onSelectedUser(option, type) {
      if (!option) {
        return
      }
      let payload = {}
      payload.company_id = option.id
      if (type === 'master_distributor') {
        this.distributor = null
        this.reseller = null
        this.client = null
        this.distributorList = []
        this.resellerList = []
        this.clientList = []

        this.fetchHardwareSelectedCompany(payload)
      }

      if (type === 'distributor') {
        this.reseller = null
        this.client = null
        this.selected = ['Distributor']

        this.resellerList = []
        this.clientList = []
        this.fetchHardwareSelectedCompany(payload)
      }

      if (type === 'reseller') {
        payload = {
          ...payload,
          ...{ reseller_id: option.id },
        }
        this.selected = ['Reseller']

        this.client = null

        this.clientList = []

        this.fetchHardwareSelectedCompany(payload)
      }

      if (type === 'client') {
        if (!this.reseller && this.distributor) {
          payload = {
            ...payload,
            ...{ distributor_id: this.distributor },
          }
        }
        this.selected = ['Client']

        payload = {
          ...payload,
          ...{ client_id: option.id },
        }

        this.fetchHardwareSelectedCompany(payload)
      }

      if (type === 'site') {
        payload = {
          ...payload,
          ...{ site_id: option.id },
        }
        delete payload.company_id
        this.fetchHardwareSelectedCompany(payload)
      }
    },
    async fetchModelList() {
      try {
        const { data } = await this.$http.get('/hardware/model/list')

        this.modelList = data.data
      } catch (error) {
        console.log('error :>> ', error)
      }
    },
    async fetchSiteList({ ...payload }) {
      try {
        const { data } = await this.$http.get('/site/list', { params: { ...payload } })

        this.siteList = data.data
      } catch (error) {
        console.log('error :>> ', error)
      }
    },
    async validationForm() {
      const valid = await this.$refs.hardwareUpdate.validate()

      if (valid) {
        try {
          let payload = {
            ...this.form,
          }
          if (this.$can('view', this.SELECT_MASTER_DISTRIBUTOR)) {
            payload = {
              ...payload,
              ...{ master_distributor_id: this.masterDistributor ? this.masterDistributor.id : null },
            }
          }

          if (this.$can('view', this.SELECT_DISTRIBUTOR)) {
            payload = {
              ...payload,
              ...{ distributor_id: this.distributor ? this.distributor.id : null },
            }
          }

          if (this.$can('view', this.SELECT_RESELLER)) {
            payload = {
              ...payload,
              ...{ reseller_id: this.reseller ? this.reseller.id : null },
            }
          }

          if (this.$can('view', this.SELECT_CLIENT)) {
            payload = {
              ...payload,
              ...{ client_id: this.client ? this.client.id : null },
            }
          }
          payload = {
            ...payload,
            ...{ site_id: this.site ? this.site.id : null },
          }

          this.isFormSubmitting = true
          await this.updateItem({
            ...{ id: this.id },
            ...payload,
          })

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success!',
              icon: 'BellIcon',
              variant: 'success',
            },
          })

          this.$router.push({ name: 'hardware-list' })
        } catch (error) {
          const { status } = error.response

          if (!status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Server error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          }

          if (status === 422) {
            const { fields } = error.response.data
            const { message } = error.response.data

            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'BellIcon',
                variant: 'danger',
                message,
              },
            })

            this.$refs.hardwareUpdate.setErrors(fields)
          }
        } finally {
          this.isFormSubmitting = false
        }
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    this.resetUserState()

    next()
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</style>
